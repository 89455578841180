import { spotonProducts } from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Louisiana State University (LSU)",
  pageDescription:
    "Learn how a Division 1 university uses SpotOn technology to eliminate long lines and serve thousands of spectators faster on game day.",
  title: "Helping fans see more touchdowns",
  subTitle: `How LSU uses SpotOn to expedite orders so fans can watch more of the game.`,
  businessName: "Louisiana State University (LSU)",
  industry: "Enterprise, education campuses",
  location: "Baton Rouge, Louisiana",
  products: [spotonProducts.POS, spotonProducts.SELF_SERVICE_KIOSKS],

  description:
    "Along with the eighth largest stadium in the world (Tiger Stadium), LSU has 11 athletic facilities that can accommodate more than 140,000 fans at any given time. And with SpotOn's cloud-based system, they have the flexibility to serve those fans anywhere using self-service kiosks.",
  quoteData: {
    imgName: "louisiana-state-university.png",
    quote: `“SpotOn made it extremely easy for us to deploy a self-service platform and shift toward the future of ordering at athletic events.”`,
    personName: "Matthew LeBorde",
    personTitle: "Assistant Athletics Director, Louisiana State University",
  },
  goal: `Because fans were missing the game while waiting at food service counters, LSU needed a way to expedite orders and eliminate long lines. They also wanted devices they could move between facilities depending on the sporting season.`,
  solution: `With SpotOn's self-service kiosks, students and guests can quickly order and pay independently without waiting in line. And because the terminals are transportable, LSU has the freedom to move them between high-traffic locations easily.`,
  results: {
    title: `The results`,
    stats: [
      { title: `16%`, description: `increase in average order size` },
      {
        title: `25%`,
        description: `more items per check at kiosks`,
      },
    ],
  },
};

export const relatedSuccessStories = [];
